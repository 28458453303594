import { setDeepLinksForParms } from '../src/configs/postproc-mol-url-parms.js'
import { setStateFromStateString } from '../src/configs/postproc-stateTracking.js'
import { filterOb } from '../src/utils/funcs.js'

function prepareSDKConfig (sc) {
  const {
    name, debug, headless, theme, defaultSearchTerms, venueId, accountId,
    poiCategories, preserveStateInURL, supportURLDeepLinks, initState,
    deepLinkParms, uiHide, renderDiv, parentConfig, desktopViewMinWidth,
    forceDesktop, hostAppId, hostAppVersion, hostAppProperties, logFilter,
    searchPlaceholder, dataFetch, engineName, dynamicPoisUrlBaseV1, plugins
  } = sc

  const extendsConfig = parentConfig ? [parentConfig] : (headless ? ['sdkHeadless'] : ['sdkVisual'])

  const trunc = (str, len) => str && str.length > len ? str.substring(0, len) : str

  const validateHostAppId = hostAppId => hostAppId ? trunc(hostAppId.toString(), 128) : undefined
  const validateHostAppVersion = hostAppVersion => hostAppVersion ? trunc(hostAppVersion.toString(), 128) : undefined
  const validateHostAppProperties = hostAppProperties => {
    if (!hostAppProperties || typeof hostAppProperties !== 'object')
      return undefined

    const ret = { } // lets build this back up ourselves to ensure its purity

    const keys = Object.keys(hostAppProperties)

    if (keys.length > 10)
      keys.length = 10

    keys.forEach(key => {
      let cleanKey = trunc(key.toString().replaceAll(/[^a-zA-Z0-9_]/g, ''), 128)
      if (!cleanKey.match(/^[a-zA-Z]+/))
        cleanKey = 'X' + cleanKey // force to start with alpha char
      let cleanVal = hostAppProperties[key]
      if (cleanVal === null || cleanVal === undefined)
        cleanVal = ''
      cleanVal = trunc(cleanVal.toString(), 128)
      ret[cleanKey] = cleanVal
    })

    return ret
  }

  const config = {
    name,
    engineName,
    extends: extendsConfig,
    debug,
    logFilter,
    theme,
    uiHide,
    renderDiv,
    configPostProc: [],
    plugins: {
      venueDataLoader: {
        dataFetch,
        venueId,
        accountId
      },
      sdkServer: {
        headless
      },
      analytics2: {
        hostAppId: validateHostAppId(hostAppId),
        hostAppVersion: validateHostAppVersion(hostAppVersion),
        hostAppProperties: validateHostAppProperties(hostAppProperties)
      }
    },
    uuid: typeof (document) !== 'undefined' && document && document.location ? document.location.host : 'unknown' // used in analytics
  }

  if (!headless)
    config.plugins['online/headerOnline'] = { searchPlaceholder }

  config.plugins.searchService = defaultSearchTerms
    ? { defaultSearchTerms }
    : { }
  handleI18NParm(sc, 'defaultSearchTerms', config.plugins.searchService)

  if (extendsConfig.includes('sdkVisual')) {
    if (poiCategories)
      config.plugins['online/homeView'] = { poiCategories }
    else
      config.plugins['online/homeView'] = { }
    handleI18NParm(sc, 'poiCategories', config.plugins['online/homeView'])
  }

  // Allow *specific* additional plugins
  if (plugins) {
    if (plugins.draw)
      config.plugins.draw = plugins.draw
  }

  if (preserveStateInURL) {
    config.configPostProc.push('stateTracking')
    config.plugins.deepLinking = { trackURL: true }
  }

  if (supportURLDeepLinks)
    config.configPostProc.push('mol-url-parms')

  if (initState)
    setStateFromStateString(config, atob(initState), true)

  if (deepLinkParms)
    setDeepLinksForParms(config, deepLinkParms, true)

  if (desktopViewMinWidth !== undefined)
    config.desktopViewMinWidth = desktopViewMinWidth

  if (forceDesktop)
    config.desktopViewMinWidth = 0

  if (dynamicPoisUrlBaseV1) {
    config.plugins.dynamicPois = {
      urlBaseV1: dynamicPoisUrlBaseV1
    }
  }

  return filterOb((k, v) => v !== undefined, config) // remove any undefined values
}

function handleI18NParm (sdkConfig, prefix, configDest) {
  Object.keys(sdkConfig)
    .filter(key => key.startsWith(prefix + '-'))
    .forEach(key => (configDest[key] = sdkConfig[key]))
}

export default prepareSDKConfig
