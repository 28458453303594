import queryString from 'query-string'

const parmToPlugin = {
  navFrom: 'online/getDirectionsFromTo',
  navTo: 'online/getDirectionsFromTo',
  accessible: 'online/getDirectionsFromTo',
  showNav: 'online/getDirectionsFromTo',
  poiId: ['online/getDirectionsFromTo', 'online/poiView'],
  vid: 'venueDataLoader',
  stage: 'venueDataLoader',
  contentStage: 'venueDataLoader',
  search: 'online/headerOnline',
  ho: ['online/getDirectionsFromTo', 'analytics2'], // handoff indicator (used in analytics)
  home: 'online/homeView', // doing a handoff to home view
  zoom: 'mapRenderer',
  pitch: 'mapRenderer',
  bearing: 'mapRenderer',
  lat: 'mapRenderer',
  lng: 'mapRenderer',
  radius: 'mapRenderer',
  buildingId: 'mapRenderer',
  floorId: 'mapRenderer',
  refInstallId: 'analytics2'
}

export function process (config) {
  const parms = queryString.parse(location.search)
  return setDeepLinksForParms(config, parms)
}

// Note: see note about security for forceCreate on postproc-stateTracking - same thing
// applies here!
export function setDeepLinksForParms (config, parms, forceCreate) {
  // allow for lldebug on the URL to set debug mode. I made it "lldebug" just so it wasn't SO easy to guess. ;-)
  if (parms.lldebug !== undefined) {
    try {
      config.debug = JSON.parse(parms.lldebug)
      if (config.debug === null) // allow for a URL parm of just `lldebug` (no value = null)
        config.debug = { }
    } catch (e) { config.debug = true }
  }
  Object.keys(parmToPlugin)
    .forEach(key => {
      if (parms[key] !== undefined) {
        let plugins = parmToPlugin[key]
        if (!Array.isArray(plugins))
          plugins = [plugins]
        plugins.forEach(plugin => {
          let pc = config.plugins[plugin] // config for this plugin
          if (!pc && forceCreate)
            pc = config.plugins[plugin] = { }
          pc.deepLinkProps = { ...pc.deepLinkProps, [key]: parms[key] }
        })
      }
    })

  if (parms.poiId && parms.showNav) // poiId doubles as a parm for both - if showNav is defined, it should target getDirectionsFromTo only
    delete config.plugins['online/poiView'].deepLinkProps.poiId

  return config
}
