
export function buildEnv (app) {
  const desktopViewMinWidth = app.config.desktopViewMinWidth || 0 // default to desktop view for all sizes
  const isBrowser = typeof window !== 'undefined'

  const env = {
    isBrowser,
    isMobile: () => isBrowser && innerWidth < desktopViewMinWidth,
    isDesktop: () => isBrowser && !env.isMobile()
  }

  if (isBrowser)
    window.addEventListener('resize', () => app.bus.send('env/resize'))

  return env
}
