import i18next from 'i18next'

export default async function init (langOverride, debugMode) {
  const i18n = i18next.createInstance()
  return i18n
    // .use(dashEmAll)
    // .use(pseudoTrans)
    .init({
      debug: debugMode,
      interpolation: {
        escapeValue: false
      },
      resources: {
        en: {
          translation: {
            'Walk xx minutes to': 'Walk {{count}} minute to',
            'Walk xx minutes to_plural': 'Walk {{count}} minutes to'
          }
        }
      },
      fallbackLng: 'en',
      // ns: ['common', 'banner', 'common', 'directions', 'failure', 'flights', 'legal', 'navigation', 'poi', 'search', 'security', 'time', 'tooltip'],
      // defaultNS: 'common',
      lng: langOverride, /* ,    interpolation: {
      escapeValue: true
    } */
      postProcess: ['pseudoTrans']
    })
    .then(() => {
      if (debugMode) {
        i18n.on('missingKey', function (lngs, namespace, key, res) {
          console.error('i18nNext: key not found. Language: ' + i18n.language + ', Key: ' + key + ', namespace: ' + namespace + ', lngs: ', lngs)
        })
      }

      // i18n.changeLanguage(lng, callback)
      // i18n.t(key, interpolation)

      return i18n
    })
}

// A postprocessor plugin for i18next that replaces all words with dashes - this helps identify untranslated strings
// const dashEmAll = {
//   name: 'dashEmAll',
//   type: 'postProcessor',
//   process: (value, key, options, translator) => value.replace(/[^ .-_,]/g, '-')
// }

// const pseudoTrans = {
//   name: 'pseudoTrans',
//   type: 'postProcessor',
//   process: (value, key, options, translator) => toLang(value, translator.language)
// }

const loremIpsum = {
  ar: `بال عن أمام الأخذ البرية. ثم كما وقام شاسعة الجنوبي, أمدها الإمداد عن انه. ٣٠ فكانت والكساد انه. أساسي الشمال الإتفاقية بال أم. زهاء المؤلّفة حين عل, سكان قررت هذه ان, شعار باستخدام ولم مع.

دول الأمور الصفحة الأوروبية قد. تم حدى فاتّبع الهجوم, ما أوسع معاملة فعل, خلاف الأوروبية، إيو كل. تُصب واندونيسيا، حين قد. أي حاول حقول وهولندا، هذا, بين عن لهيمنة بالسيطرة.

أعمال الإتحاد العمليات من وصل. وقوعها، باستخدام قد أخر, بل حتى علاقة التبرعات. فقد لم الأرض استبدال. حتى الخاسر اقتصادية بـ. أصقاع أعلنت اتفاقية تلك بل, كان إذ بحشد أخرى ليبين. الشتاء، اليابان، مكن أم, به، قد العالم، أوراقهم, بل لعدم لغزو أواخر مكن.
`,
  ja: '私は場合いよいよある所有らってのの末がしたた。無論結果に圧迫顔もよくその発展ないですまでを決するてみるでしでも発展云いませたから、そうには描いたないたた。機会へ載っない方は別に今をひょろひょろでずまし。むしろ嘉納さんを意味方面そう＃「を積んない権力この自分何か記念をというおお尋ねだろででですで、ある今はこれか頼み例があるで、ネルソンさんののの引込のあなたにもっともお努力といと私赤に実建設が起っようにとやかくご附随を描いですましから、どうもとにかく相違に云おでてならますのが行っないませ。'
}

export const toLang = (str, lang) => lang === 'en' ? str : repl(str, loremIpsum[lang])
const repl = (str, r) => str.length < r.length ? r.substring(0, str.length) : r
